import React, { useEffect, useState } from "react";

/**Import Styles and Images */
import styles from "./UserManagement.module.scss"

import TableLayout from "../../components/TableLayout";
import { useUserManagementHeader } from "../../schemas/tableHeaderSchema";


import { useSelector, useDispatch } from "react-redux";
import { fetchUserManagementThunk } from "../../features/UserManagementReducer";
import { resetValue } from "../../features/SearchReducer";


export default function UserManagement() {
    const header = useUserManagementHeader();
    const { data, status } = useSelector(state => state.userManagement);
    const { search } = useSelector(state => state.search);
    const [list, setList] = useState(data);
    const dispatch = useDispatch();

    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchUserManagementThunk());
        }

        return () => dispatch(resetValue());
    }, [])

    useEffect(() => {
        if (!search) return setList(data);


        const array = [
            ...data.map(item => {
                if (item.username.toLowerCase().includes(search.toLowerCase())) {
                    return item
                }
            })
        ].filter(Boolean)
        setList(array);

    }, [data, search])




    return (
        <div className={styles.userManagement}>
            <h1 className={styles.contentTitle}>User Management</h1>
            <TableLayout headers={header} data={list} status={status} />
        </div>
    )
}