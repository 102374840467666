import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Constant } from '../Api/Constant';
import { Toast } from '../hooks/useToast';


export const fetchConstantThunk = createAsyncThunk(
    'constant/fetch',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Constant.fetch({ api_token });
            return response.data
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);

        }
    }
)

export const updateConstantThunk = createAsyncThunk(
    'constant/update',
    async (_, { getState }) => {
        const { data } = getState().constantManagement;
        const { api_token } = getState().profile.data;
        Toast("Updating Data", 'loading', true);

        try {
            const response = await Constant.update({ data, api_token });
            Toast(response.message, "success", false);
            return response.data
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message);


        }
    }
)

const initialState = {
    status: "pending",
    data: {
        cash_per_View: 0,
        exchange_rate: 0
    },
}

export const constantSlice = createSlice({
    name: 'constant',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        setValue: (state, actions) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...actions.payload

                }
            }
        },
        resetState: () => {
            return initialState
        },
    },
    extraReducers: {
        [fetchConstantThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [fetchConstantThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [fetchConstantThunk.rejected]: (state, action) => {
            state.status = "loading"
        },
        [updateConstantThunk.fulfilled]: (state, action) => {
            state.status = "success"
        },
        [updateConstantThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateConstantThunk.error]: (state, action) => {
        
            state.status = "error"
        },
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, setValue, resetState } = constantSlice.actions

export default constantSlice.reducer