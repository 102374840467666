import React from "react";
import { Link } from "react-router-dom"
import styles from "./ProtectedLayout.module.scss";
import Navbar from "./Navbar";


import { Layout } from 'antd';
import { confirmAlert } from 'react-confirm-alert';

/**Import Assets */
import { useDispatch } from "react-redux";
import { updateKey } from "../../features/DefaultReducer";
import link1 from "./images/1.svg";
import link2 from "./images/2.svg";
import link5 from "./images/5.svg";
import link7 from "./images/7.svg";
import link8 from "./images/8.svg";
import link9 from "./images/9.svg";
import link10 from "./images/10.svg";
import notification from "./images/noti.svg";
import edit from "./images/edit.svg";
import category from "./images/category.svg";
import business from "./images/business.svg";
import contact from "./images/contact.svg";



import { resetState as profileReset } from "../../features/ProfileReducer";
import { resetState as reportReset } from "../../features/ReportManagementReducer";
import { resetState as textReset } from "../../features/StaticTextReducer";
import { resetState as userReset } from "../../features/UserManagementReducer";
import { resetState as dashboardReset } from "../../features/DashboardReducer";
import { resetValue as searchReset } from "../../features/SearchReducer";
import { resetState as categoryReset } from "../../features/CategoryManagementReducer";



const { Sider } = Layout;

export default function ProtectedLayout({ selectedRoute, isSearchBar, children }) {

    const dispatch = useDispatch();


    const items = [
        {
            icon: link1,
            label: "Dashboard",
        },
        {
            icon: link2,
            label: "User Managment",
        },
        {
            icon: category,
            label: "Business Category Management",
        },
        {
            icon: business,
            label: "Business Management",
        },
        {
            icon: link5,
            label: "User Report Management",
        },
        {
            icon: link7,
            label: "Terms And Conditions",
        },
        {
            icon: link8,
            label: "Privacy Policy",
        },
        {
            icon: link9,
            label: "FAQs",
        },
        {
            icon: notification,
            label: "Notifications",
        },
        {
            icon: edit,
            label: "Change Password",
        },
        {
            icon: contact,
            label: "Contact Detail",
        },


    ]

    function handleLogout() {
        dispatch(updateKey({ isLoggedIn: false }));
        dispatch(profileReset());
        dispatch(reportReset());
        dispatch(textReset())
        dispatch(userReset())
        dispatch(dashboardReset())
        dispatch(searchReset())
        dispatch(categoryReset());

    }

    function submit(title, message, cb, rejectCB = () => { }) {
        confirmAlert({
            title,
            message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => cb()
                },
                {
                    label: 'No',
                    onClick: () => rejectCB()
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });
    }



    return (
        <div className={styles.protectedLayout}>
            <Sider trigger={null} style={{ height: "100vh" }} className={styles.sider} width={335} >
                <div className={styles.children}>

                    <Link className={styles.logo} to={`/dashboard`}>
                        <img src="logo.png" alt="Logo Image" />

                    </Link>
                    <div className={styles.menu}>
                        {items.map(({ icon, label }, index) => {
                            return <Link to={`/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}`} className={`${styles.item} ${index == selectedRoute ? styles.active : ""}`} key={index}>
                                <img src={icon} alt={`Icon${index}`} width={20} height={20} />
                                <p>{label}</p>
                            </Link>
                        })}
                        <Link className={styles.item} onClick={() => submit("Confirm Logout", "Are you sure you want to logout?", () => handleLogout())} >
                            <img src={link10} alt="Icon1" width={20} height={20} />
                            <p>Logout</p>
                        </Link>

                    </div>

                </div>


            </Sider >
            <div className={styles.routeContent}>
                <Navbar isSearchBar={isSearchBar} />
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div >
    )
}