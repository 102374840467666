import React, { useState } from "react";
import _ from "lodash";

import styles from "./index.module.scss";


import { LoadingOutlined } from '@ant-design/icons';
import { Toast } from "../../hooks/useToast";
import { Profile } from "../../Api/Profile";
import { useSelector } from "react-redux";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md"

const antIcon = <LoadingOutlined style={{ fontSize: 12, color: "white" }} spin />;



export default function ChangePassword() {

    const { api_token } = useSelector(state => state.profile.data);


    const [data, setData] = useState({
        current_password: "",
        new_password: "",
        confirm_password: ""
    });
    const [passType, setPassType] = useState("password");
    const [confirmPassType, setConfirmPassType] = useState("password");
    const [newPassType, setNewPassType] = useState("password");

    function handleChange(e) {
        const { name, value } = e.target;
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    async function handleSubmit() {

        const format = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,150}$/;

        const { current_password, new_password, confirm_password } = data;
        if (!current_password || !new_password || !confirm_password) return Toast("Field Should Not Be Empty", 'error', false);
        if (!new_password.match(format)) return Toast('Password should be atleast 8 characters long and contain number, capital letter and special character', 'error', false);
        if (new_password !== confirm_password) return Toast("Confirm Password Is Not Same As New Password", 'error', false);

        Toast('Updating Password', 'loading', true);
        try {
            const record = await Profile.update(data, api_token);
            Toast('Password Has Been Updated', 'success', false);

        }
        catch (e) {
            Toast(e.data.message, 'error', false);
        }

        finally {
            return;
        }

    }

    return (
        <div className={styles.changePassword}>
            <h1 className={styles.contentTitle}>Change Password</h1>
            <div className={styles.content}>
                <div className={styles.row}>
                    <div className={styles.inputFields}>
                        <input
                            type={passType}
                            placeholder="Password"
                            autoComplete="off"
                            name="current_password"
                            value={data.current_password}
                            onChange={handleChange}
                        />
                        {(passType == 'password') ? <button onClick={() => setPassType('text')}><MdOutlineVisibility /></button> :
                            <button onClick={() => setPassType('password')}><AiOutlineEyeInvisible /></button>}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.inputFields}>
                        <input
                            type={newPassType}
                            placeholder="New Password"
                            autoComplete="off"
                            name="new_password"
                            value={data.new_password}
                            onChange={handleChange}
                        />
                        {(newPassType == 'password') ? <button onClick={() => setNewPassType('text')}><MdOutlineVisibility /></button> :
                            <button onClick={() => setNewPassType('password')}><AiOutlineEyeInvisible /></button>}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.inputFields}>
                        <input
                            type={confirmPassType}
                            placeholder="Confirm Password"
                            autoComplete="off"
                            name="confirm_password"
                            value={data.confirm_password}
                            onChange={handleChange}
                        />
                        {(confirmPassType == 'password') ? <button onClick={() => setConfirmPassType('text')}><MdOutlineVisibility /></button> :
                            <button onClick={() => setConfirmPassType('password')}><AiOutlineEyeInvisible /></button>}
                    </div>
                </div>
                <button className={styles.submit} onClick={handleSubmit}>
                    Update
                </button>

            </div>

        </div>
    )
}