import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";


/**Import Styles and Assets */
import styles from "./PrivacyPolicy.module.scss";

import { fetchStaticTextThunk, updatePrivacyThunk } from "../../features/StaticTextReducer";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { AiFillEdit, AiFillSave } from "react-icons/ai";
import { ImCancelCircle } from "react-icons/im";
const antIcon = <LoadingOutlined style={{ fontSize: 35, color: "black" }} spin />;

export default function PrivacyPolicy() {
    const { data, status } = useSelector(state => state.staticText);
    const dispatch = useDispatch();

    const [isEditable, setIsEditable] = useState(false);
    const inputRef = useRef(null);



    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchStaticTextThunk());
        }
    }, [])

    useEffect(() => {
        if (status === "success") {
            inputRef.current.value = data?.privacy ? data.privacy : "";
            setIsEditable(false);
        }

    }, [data])

    function cancelEdit() {
        setIsEditable(false);
        inputRef.current.value = data?.privacy ;
    }

    function handleSave() {
        dispatch(updatePrivacyThunk({ privacy: inputRef.current.value }));
    }
    return (
        <div className={styles.privacyPolicy}>
            <div className={styles.title}>
                <h1 className={styles.contentTitle}>Privacy</h1>
                {
                    !isEditable ?
                        <button onClick={() => { setIsEditable(true); inputRef.current.focus(); }}><AiFillEdit /></button> :
                        <div className={styles.save}>
                            <button onClick={handleSave}><AiFillSave /></button>
                            <button onClick={cancelEdit}><ImCancelCircle /></button>
                        </div>

                }

            </div>
            <div className={styles.content}>
                {data?.privacy ? <textarea readOnly={!isEditable} ref={inputRef} /> : <Spin indicator={antIcon} size="large" />}
            </div>

        </div>
    )
}