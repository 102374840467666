import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';



const initialState = {
    search: "",
    isVisible: true
}

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setValue: (state, actions) => {
            return {
                ...state,
                search: actions.payload,
            }
        },
        setVisible: (state, actions) => {
            return {
                ...state,
                isVisible: actions.payload,
            }
        },
        resetValue: (state, actions) => {
            return {
                ...state,
                search: ""
            }
        }
    },
})


// Action creators are generated for each case reducer function
export const { setValue, resetValue, setVisible } = searchSlice.actions

export default searchSlice.reducer