import React, { useState } from "react";

/**Import Styles and Assets  */
import styles from "./Login.module.scss";
import logo from "./images/logo.svg"

import { Axios } from "../../hooks/useAxiosInstance.js";
import { Toast } from "../../hooks/useToast";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md"

import { useDispatch } from "react-redux";
import { updateKey } from "../../features/DefaultReducer";
import { postForgetpasswordThunk, postProfileThunk } from "../../features/ProfileReducer";

export default function Login({ switchPage }) {

    const dispatch = useDispatch();


    const [loginForm, setLoginForm] = useState(true);
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
    })
    const [sendMail, setSendMail] = useState("");
    const [isHidden, setIsHidden] = useState(true);

    const formLayout = {
        name: "Login Account",
        message: "Login to continue using your account",
        elements: [
            <div className={styles.inputFields}>
                <input key="1" type="text" placeholder="Email" autoComplete="off" name="email" value={loginData.email} onChange={handleLoginDataChange} />
            </div>,
            <div className={styles.inputFields}>
                <input key="2" type={isHidden ? "password" : "text"} placeholder="Password" name="password" value={loginData.password} onChange={handleLoginDataChange} />
                {isHidden ? <button onClick={() => setIsHidden(false)}><MdOutlineVisibility /></button> :
                    <button onClick={() => setIsHidden(true)}><AiOutlineEyeInvisible /></button>}
            </div>
        ],
        submitButton: <button className={styles.submit} onClick={handleSubmit}>Login</button>,
    }

    const sendMailLayout = {
        name: "Forgot Password",
        message: "Please Enter Email Address Assosiated With Your Account",
        elements: [
            <div className={styles.fields}>
                <div className={styles.inputFields}>
                    <input key="1" type="text" placeholder="Email" autoComplete="off" name="sendMail" value={sendMail} onChange={(e) => setSendMail(e.target.value)} />

                </div>
            </div>

        ],
        submitButton: <button className={styles.submit} onClick={handleSubmit}>Send</button>,
    }



    function handleLoginDataChange(e) {
        const { name, value } = e.target;
        setLoginData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })

    }

    function ValidateEmail(value) {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return value.match(validRegex) ? true : false;

    }


    async function handleSubmit() {
        if (loginForm) {
            if (!loginData.email || !loginData.password) {
                Toast('Fields should not be empty', 'error', false);

            }
            else if (!ValidateEmail(loginData.email)) {
                Toast('Invalid Email', 'error', false);
            }
            else {
                dispatch(postProfileThunk(loginData));
            }
        }
        else {

            if (!sendMail) {
                Toast('Field should not be empty', 'error', false);

            }
            else if (!ValidateEmail(sendMail)) {
                Toast('Invalid Email', 'error', false);
            }
            else {
                dispatch(postForgetpasswordThunk({ email: sendMail, setLoginForm }));
            }

        }
    }

    return (
        <div className={styles.main}>
            <div className={styles.layout}>

                <div className={styles.content}>
                    <img draggable="false" src={logo} alt="Logo" />
                    {loginForm ? <LoginForm formData={formLayout} /> :
                        <LoginForm formData={sendMailLayout} />
                    }
                    <p className={styles.switch} onClick={() => setLoginForm(!loginForm)}>{loginForm ? "Forgot Password?" : "Return to Login"}</p>

                </div>

            </div>
        </div>
    )
}

export function LoginForm({ formData }) {

    const { name, message, elements, submitButton } = formData
    return (
        <div className={styles.form}>
            <h1>{name}</h1>
            <p>{message}</p>

            <div className={styles.fields}>
                {elements.map(item => {
                    return item;
                })}
            </div>
            {submitButton}

        </div >
    )
}

