import React from "react";
import styles from "./ProtectedLayout.module.scss";
import moment from "moment"


import { FaCalendarAlt } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";

import { useSelector, useDispatch } from "react-redux";

import { setValue } from "../../features/SearchReducer";


export default function Navbar({ isSearchBar }) {

    const { search, isVisible } = useSelector(state => state.search);
    console.log(isVisible)
    const dispatch = useDispatch();



    return (
        <div className={styles.Navbar}>
            <div className={styles.date}>
                <FaCalendarAlt />
                <p>{moment(new Date()).format('MMMM DD,  YYYY')}</p>
            </div>
            {(isSearchBar && isVisible) ?
                <div className={styles.search}>
                    <input type="text" placeholder="Search by name..." value={search} onChange={(e) => dispatch(setValue(e.target.value))} />
                    <div className={styles.searchButton}>
                        <AiOutlineSearch />

                    </div>

                </div>
                : ""}

        </div >
    )
}