import { Axios } from "../hooks/useAxiosInstance";

export const UserManagement = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        fetch: async ({ api_token }) => await fetchRecord(api_token),
        update: async ({ data, api_token }) => await updateRecord(data, api_token),
    })

    async function fetchRecord(api_token) {
        const url = "api/admin/getAllUsers";
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }

    async function updateRecord(data, api_token) {
        const body = {
            user_id: data.user_id
        }
        const url = "api/admin/" + (data.status ? "userDeactive" : "userActive");
        const option = {
            headers: {
                'token': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, body, option);
    }


    return apis();

})()
