import { Axios } from "../hooks/useAxiosInstance";

export const Notification = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        post: async (data, token) => await postRecord(data, token),
    })

    async function postRecord(data, token) {
        const url = "api/business/sendTopic";
        const option = {
            headers: {
                'token': token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }


    return apis();

})()
