import moment from "moment";

export const validateEmail = (value) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return value.match(validRegex)
}


export const sliceText = (str, length) => {
    const val = str.slice(0, length);
    return val + ((str.length > length) ? "..." : "")
}

export const formattedDate = (date) => {
    return moment(date).format('DD MMM, YYYY')
}

export const roundOffTo = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier
}