import React, { useRef, useState } from "react";
import ReactDom from "react-dom";
import styles from "./CategoryModal.module.scss";

export default function CategoryModal({ setIsOpen, handleSubmit }) {

    const ref = useRef();



    return ReactDom.createPortal(
        <div className={styles.modal}>
            <div className={styles.newFaq}>
                <div className={styles.headerContainer}>
                    <h1>Add Category</h1>
                    <div className={styles.cancel}>
                        <button onClick={() => setIsOpen(false)}>X</button>
                    </div>
                </div>
                <div className={styles.submitFaq}>
                    <input type="text" placeholder="Category" ref={ref} />
                </div>
                <div className={styles.save}>
                    <button onClick={() => handleSubmit(ref.current.value)}>Add</button>
                </div>

            </div>

        </div>,
        document.getElementById('portal')
    )
}