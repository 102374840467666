import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { StaticText } from '../Api/StaticText';
import { Toast } from '../hooks/useToast';


export const fetchStaticTextThunk = createAsyncThunk(
    'staticText/fetch',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await StaticText.fetch({ api_token });
            return response.data
        } catch (err) {
            Toast(err.message,'error',false);
            return Promise.reject(err);
        }
    }
)




export const insertFaqThunk = createAsyncThunk(
    'faq/insert',
    async (data, { getState }) => {
        Toast('Adding New Faq', 'loading', true);
        try {
            const { api_token } = getState().profile.data;
            const response = await StaticText.insertFaq({ data, api_token });
            Toast(response.message, 'success', false);
            return response.data[0]
        } catch (err) {
            Toast(err.message, 'error', false);
            return Promise.reject(err.message)
        }
    }
)

export const deleteFaqThunk = createAsyncThunk(
    'faq/delete',
    async (data, { getState }) => {
        Toast('Deleting Faq', 'loading', true);
        try {
            const { api_token } = getState().profile.data;
            const response = await StaticText.deleteFaq({ data, api_token });
            Toast(response.message, 'success', false);
            return data
        } catch (err) {
            Toast(err.message, 'error', false);
            return Promise.reject(err.message)
        }
    }
)

export const updateFaqThunk = createAsyncThunk(
    'faq/update',
    async ({ data, setIsModal }, { getState }) => {
        Toast('Updating Faq', 'loading', true);
        try {
            const { id, question, answer } = data;
            const body = {
                faqId: id,
                question,
                answer
            }
            const { api_token } = getState().profile.data;
            const response = await StaticText.updateFaq({ data: body, api_token });
            Toast(response.message, 'success', false);
            setIsModal(false);
            return data
        } catch (err) {
            Toast(err.message, 'error', false);
            return Promise.reject(err.message)
        }
    }
)

export const updatePrivacyThunk = createAsyncThunk(
    'privacy/update',
    async (data, { getState }) => {
        Toast('Updating privacy policy', 'loading', true);
        try {
            const { api_token } = getState().profile.data;
            const body = {
                name: "privacy policy",
                content_type: "privacy_policy",
                value: data.privacy,
                status: 1
            }
            const response = await StaticText.updatePrivacy({ data: body, api_token });
            Toast(response.message, 'success', false);
            return data
        } catch (err) {
            Toast(err.message, 'error', false);
            return Promise.reject(err.message);
        }
    }
)

export const updateTermsThunk = createAsyncThunk(
    'terms/update',
    async (data, { getState }) => {
        Toast('Updating terms and condition', 'loading', true);
        try {
            const { api_token } = getState().profile.data;
            const response = await StaticText.updateTerms({ data, api_token });
            Toast(response.message, 'success', false);
            return data
        } catch (err) {
            Toast(err.message, 'error', false);
            return Promise.reject(err.message);
        }
    }
)

export const updateContactThunk = createAsyncThunk(
    'contact/update',
    async (data, { getState }) => {
        const { api_token } = getState().profile.data;
        const { email, mobile_no, description } = data;
        Toast("Updating Profile", "loading", true);
        try {
            const body = {
                cont_email: email,
                cont_numbers: mobile_no,
                cont_description: description

            }
            const response = await StaticText.updateInfo(body, api_token);
            Toast('Contact details updated successfully', "success", false);
            return data;
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message)
        }
    }
)




const initialState = {
    status: "pending",
    data: {},
}

export const staticTextSlice = createSlice({
    name: 'staticText',
    initialState,
    reducers: {
        resetState: () => {
            return initialState
        },
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        setValue: (state, actions) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...actions.payload

                }
            }
        },

    },
    extraReducers: {
        [fetchStaticTextThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [fetchStaticTextThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [fetchStaticTextThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [updateTermsThunk.fulfilled]: (state, action) => {
            state.data = {
                ...state.data,
                ...action.payload
            }
            state.status = "success"
        },
        [updateTermsThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateTermsThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [insertFaqThunk.fulfilled]: (state, action) => {
            state.status = "success"
            state.data = {
                ...state.data,
                faq: [
                    action.payload,
                    ...state.data.faq,
                ]
            }
        },
        [insertFaqThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [insertFaqThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [deleteFaqThunk.fulfilled]: (state, action) => {
            state.status = "success"
            state.data = {
                ...state.data,
                faq: state.data.faq.filter((item) => item.id != action.payload.id)
            }
        },
        [deleteFaqThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [deleteFaqThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [updateFaqThunk.fulfilled]: (state, action) => {
            state.status = "success";
            state.data = {
                ...state.data,
                faq: state.data.faq.map((item) => {
                    if (item.id != action.payload.id) return item;
                    return action.payload
                })
            }
        },
        [updateFaqThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateFaqThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [updatePrivacyThunk.fulfilled]: (state, action) => {
            state.status = "success"
            state.data = {
                ...state.data,
                ...action.payload
            }
        },
        [updatePrivacyThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updatePrivacyThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [updateContactThunk.fulfilled]: (state, action) => {
            const { description, mobile_no, email } = action.payload
            state.status = "success"
            state.data = {
                ...state.data,
                contact: [
                    {
                        numbers: mobile_no,
                        description,
                        email
                    }
                ]
            }
        },
        [updateContactThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateContactThunk.rejected]: (state, action) => {
            state.status = "success"
        },
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, setValue, resetState } = staticTextSlice.actions

export default staticTextSlice.reducer