import React, { useState } from "react";

import styles from "./Notification.module.scss";

import { Toast } from "../../hooks/useToast";
import { Notification } from "../../Api/Notification";

import { useSelector } from "react-redux";



export default function VariablePage() {

    const { api_token } = useSelector(state => state.profile.data)

    const [data, setData] = useState({
        title: "",
        description: ""
    })

    function handleChange(name, value) {
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }


    async function handleSubmit() {
        if (!data.title) {
            Toast('Title Should Not Be Empty', 'error', false);

        }
        else if (!data.description) {
            Toast('Description Should Not Be Empty', 'error', false);
        }
        else {
            Toast("Sending Notification", 'loading', true);
            try {
                const body = {
                    title: data.title,
                    body: data.description,
                    topic: "admin"
                }
                await Notification.post(body, api_token);
                Toast("Notification Sent", 'success', false);
                setData({
                    title: "",
                    description: ""
                })

            }
            catch (e) {
                Toast(e.data.message, 'error', false);
            }
            finally {
                return;
            }
        }
    }

    return (
        <div className={styles.notification}>
            <h1 className={styles.contentTitle}>Send Notification </h1>
            <div className={styles.content}>
                <div className={styles.row}>
                    <h2>
                        Title
                    </h2>
                    <div className={styles.updateInput}>
                        <input type="text" value={data.title} onChange={(e) => handleChange('title', e.target.value)} placeholder="Enter Title" className={styles.input} />

                    </div>
                </div>
                <div className={styles.row}>
                    <h2>
                        Description
                    </h2>
                    <div className={styles.updateInput}>
                        <input type="text" value={data.description} onChange={(e) => handleChange('description', e.target.value)} placeholder="Enter Description" className={styles.input} />

                    </div>
                </div>
                <button className={styles.submit} onClick={handleSubmit}>
                    Send
                </button>

            </div>

        </div>
    )
}