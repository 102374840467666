import React, { useState } from "react";
import ReactDom from "react-dom";
import styles from "./modal.module.scss";

export default function Modal({ data, setIsOpen, handleSave }) {
    const [state, setState] = useState(JSON.parse(data));

    function handleChange(e) {
        const { name, value } = e.target;
        setState(prev => {
            return {
                ...prev,
                [name]: value
            }
        })

    }



    return ReactDom.createPortal(
        <div className={styles.modal}>
            <div className={styles.newFaq}>
                <div className={styles.cancel}>
                    <button onClick={() => setIsOpen(false)}>X</button>
                </div>
                <div className={styles.submitFaq}>
                    <input type="text" placeholder="Enter Question here..." name="question" value={state.question} onChange={handleChange} />
                </div>
                <textarea rows={5} placeholder="Enter Answer here..." name="answer" value={state.answer} onChange={handleChange} />
                <div className={styles.save}>
                    <button onClick={() => handleSave(state)}>Save</button>
                </div>

            </div>

        </div>,
        document.getElementById('portal')
    )
}