import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReportManagement } from '../Api/ReportManagement';
import { UserManagement } from '../Api/UserManagement';
import { Toast } from '../hooks/useToast';
import { updateStatus } from './UserManagementReducer';


export const fetchReportManagementThunk = createAsyncThunk(
    'reportManangement/fetch',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await ReportManagement.fetch({ api_token });
            return response.data
        } catch (err) {
            Toast(err.message, 'error', false);
            return Promise.reject(err);
        }
    }
)

export const updateReportManagementThunk = createAsyncThunk(
    'reportManangement/update',
    async (data, { getState, dispatch }) => {
        try {
            dispatch(updateReportStatus({ id: data.user_id }))
            const { api_token } = getState().profile.data;
            const response = await UserManagement.update({ data, api_token });
            dispatch(updateStatus(data))
            return {}
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message)
        }
    }
)

const initialState = {
    status: "pending",
    data: [],
}

export const reportManagementSlice = createSlice({
    name: 'reportManagement',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        resetState: () => {
            return initialState
        },
        updateReportStatus: (state, actions) => {
            const { id } = actions.payload;
            return {
                ...state,
                data: [
                    ...state.data.map(item => {
                        if (item.reported_id === id) {
                            return {
                                ...item,
                                reported_status: !item.reported_status,
                            }
                        }
                        else {
                            return item;
                        }
                    })
                ]
            }
        }
    },
    extraReducers: {
        [fetchReportManagementThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [fetchReportManagementThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [updateReportManagementThunk.fulfilled]: (state, action) => {
            state.status = "success"
        },
        [updateReportManagementThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateReportManagementThunk.rejected]: (state, action) => {
            state.status = "error"
        },
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, updateReportStatus, resetState } = reportManagementSlice.actions

export default reportManagementSlice.reducer